import ColabField from '../ColabField';
import ColabInfoChild from '../ColabInfoChild';
import styles from './ColabBody.module.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';
import { formatarData } from '../../Funcoes/FormatarData';
import { formatCPF } from '../../Funcoes/FormatCPF';
import { formatDate } from '../../Funcoes/FormatData'


const ColabBody = ({ colabInfo, loading, userID, matricula }) => {

    const [formValues, setFormValues] = useState({
        cracha: colabInfo.CRACHA || "",
        contrato: colabInfo.CONTRATO || "",
        disciplina: colabInfo.DISCIPLINA || "",
        encarregado: colabInfo.ENCARREGADO || "",
        supervisor: colabInfo.SUPERVISOR || "",
        supGeral: colabInfo.SUP_GERAL || "",
        hosped: colabInfo.HOSPEDAGEM || "",
        locado: colabInfo.LOCADO || "",
        user: userID,
        matricula: matricula,
        cpf: colabInfo.CPF
    });
    const [alojValues, setAlojValues] = useState({
        alojamento: "",
        quarto: "",
        check_in: "",
        check_out: ""
    })
    const [locacaoValues, setLocacaoValues] = useState({
        alojamento: "",
        quarto: ""
    })

    const [ folgasCampo, setFolgasCampo] = useState([])

    useEffect(() => {

        setFormValues({
            cracha: colabInfo.CRACHA || "",
            contrato: colabInfo.CONTRATO || "",
            disciplina: colabInfo.DISCIPLINA || "",
            encarregado: colabInfo.ENCARREGADO || "",
            supervisor: colabInfo.SUPERVISOR || "",
            supGeral: colabInfo.SUP_GERAL || "",
            hosped: colabInfo.HOSPEDAGEM || "",
            locado: colabInfo.LOCADO || "",
            user: userID,
            matricula: matricula,
            cpf: colabInfo.CPF
        });

        setAlojValues({
            alojamento: colabInfo.ALOJAMENTO || "",
            quarto: colabInfo.QUARTO_ALOJ || "",
            check_in: colabInfo.CHECK_IN || "",
            check_out: colabInfo.CHECK_OUT || "",
            user: userID,
            matricula: matricula,
        })

        setLocacaoValues({
            alojamento: colabInfo.ALOJ_TEMP,
            quarto: colabInfo.QUARTO_ALOJ_TEMP,
            user: userID,
            matricula: matricula,
        })

        const getFolgasCampo = async () => {

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetFolgas/', {
                    matricula: colabInfo.MATRICULA
                })

                console.log( data )
                setFolgasCampo( data )

            }catch( error ){

                console.log( error );

            }

        }

        getFolgasCampo();

    }, [colabInfo]);

    const disciplinas = [
        "ALMOXARIFADO/MATERIAIS",
        "ANDAIMES",
        "APOIO OPERACIONAL",
        "CALDEIRARIA",
        "CONSTRUÇÃO CIVIL",
        "ELETRICA E INSTRUMENTAÇÃO",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGAS",
        "ISOLAMENTO TÉRMICO",
        "LOGÍSTICA E OPERAÇÃO",
        "MECÂNICA DE MANUTENÇÃO",
        "MECÂNICA",
        "QUALIDADE",
        "RESTAURANTE",
        "SERVIÇOS ADMINISTRATIVOS",
        "SERVIÇOS DE PINTURA",
        "SERVIÇOS GERAIS",
        "SOLDAGEM",
        "SSMA",
        "TUBULAÇÃO"
    ]

    const [btnSave1, setBtnSave1] = useState('SALVAR EDIÇÕES')
    const [btnSave2, setBtnSave2] = useState('SALVAR EDIÇÕES')
    const [btnSave3, setBtnSave3] = useState('SALVAR EDIÇÕES')
    const [btnSave1Disable, setBtnSave1Disable] = useState(false)
    const [btnSave2Disable, setBtnSave2Disable] = useState(false)
    const [btnSave3Disable, setBtnSave3Disable] = useState(false)

    const [contratos, setContratos] = useState([])
    const [lideres, setLideres] = useState([])
    const [obras, setObras] = useState([])
    const [republicas, setRepublicas] = useState([])
    const [quartos, setQuartos] = useState([])
    const [alocReps, setAlocReps] = useState([])
    const [alocQuartos, setAlocQuartos] = useState([])

    const getAlocReps = async (obra) => {

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetAlojamentos/', {
                obra: obra
            })

            return (data)

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {

        const getContratos = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getContratos.php')
                setContratos(data.filter(value => value.centro_custo === colabInfo.CENTRO_CUSTO).map(contrato => contrato.nome_contrato))

            } catch (error) {

                console.log(error)

            }

        }

        const getLideres = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php')
                setLideres(data.map(value => value.RA_NOMECMP))

            } catch (error) {

                console.log(error)

            }

        }

        const getActiveObras = async () => {

            try {

                const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/")
                setObras(data.map(obra => obra.OBRA))

            } catch (error) {

                console.log(error)

            }

        }

        const getRepublicas = async () => {

            try {

                const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/GetAlojamentos/", {
                    obra: colabInfo.CENTRO_CUSTO
                })

                const republicasFormat = data.map((obj) => {

                    return {
                        label: obj.apelido_rep,
                        value: obj.identifier,
                        quartos: obj.quartos
                    }

                })

                let republica = data.filter((obj) => obj.identifier === colabInfo.ALOJAMENTO)[0]

                let formatQuartos = {}

                if (republica.quartos.length > 0) {

                    formatQuartos = republica.quartos.map((obj) => {

                        return {

                            label: obj.quarto_name,
                            value: obj.identifier

                        }

                    })

                }

                setQuartos(formatQuartos)
                setRepublicas(republicasFormat)

            } catch (error) {

                console.log(error)

            }

        }

        getContratos();
        getLideres();
        getActiveObras();

        if (colabInfo.CENTRO_CUSTO) {

            getRepublicas();

        }

    }, [colabInfo])

    const handleChange = (key, value) => {

        setFormValues(prevState => ({

            ...prevState,
            [key]: value

        }))

    }

    const handleChangeAloj = (key, value) => {

        setAlojValues(prevState => ({

            ...prevState,
            [key]: value

        }))

    }

    const handleChangeLocacao = (key, value) => {

        setLocacaoValues(prevState => ({

            ...prevState,
            [key]: value

        }))

    }

    const handleSubmit1 = async (e) => {

        e.preventDefault();
        setBtnSave1('SALVANDO EDIÇÕES...')
        setBtnSave1Disable(true);

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveColabInfo/', formValues)
            console.log(data);

        } catch (error) {

            console.log(error);

        } finally {

            setBtnSave1('SALVAR EDIÇÕES')
            setBtnSave1Disable(false);

        }

    }

    const handleSubmit2 = async (e) => {

        e.preventDefault();
        setBtnSave2('SALVANDO EDIÇÕES...')
        setBtnSave2Disable(true);

        try {

            const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/SaveColabHosp/", alojValues)
            console.log(data)

        } catch (error) {

            console.log(error)

        } finally {

            setBtnSave2("SALVAR EDIÇÕES")
            setBtnSave2Disable(false);

        }

    }

    const handleSubmit3 = async (e) => {

        e.preventDefault();
        setBtnSave3('SALVANDO EDIÇÕES...')
        setBtnSave3Disable(true);

        try {

            const { data } = await axios.post("https://sistemaintegrado.palmont.com.br/Painel/API/SaveColabLocacao/", locacaoValues)
            console.log(data)

        } catch (error) {

            console.log(error)

        } finally {

            setBtnSave3('SALVAR EDIÇÕES')
            setBtnSave3Disable(false);

        }

    }

    useEffect(() => {

        console.log(alojValues);

        const republicaEncontrada = republicas.find(
            (obj) => obj.value === alojValues.alojamento
        );

        let quartos = [];

        if (republicaEncontrada && republicaEncontrada.quartos.length > 0) {

            quartos = republicaEncontrada.quartos.map((obj) => ({
                value: obj.identifier,
                label: obj.quarto_name,
            }));

        }

        if (colabInfo && colabInfo.length > 0) {

            const quartoEncontrado = quartos.find(
                (obj) => obj.value === alojValues.quarto
            )

            if (!quartoEncontrado) {

                setAlojValues(prevValues => ({

                    ...prevValues,
                    quarto: "",
                    check_in: "",
                    check_out: ""

                }))

            }

        }

        setQuartos(quartos);

    }, [alojValues.alojamento]);

    useEffect(() => {

        if (!loading) {

            const fetchAlocReps = async () => {

                let republicasAlocado = [];

                if (formValues.locado !== '') {

                    try {

                        let response = await getAlocReps(formValues.locado);
                        republicasAlocado = response.map((obj) => {

                            return {
                                label: obj.apelido_rep,
                                value: obj.identifier,
                                quartos: obj.quartos
                            }

                        })

                    } catch (error) {

                        console.error('Erro ao obter alojamentos:', error);

                    }

                }

                setAlocReps(republicasAlocado);

            };

            fetchAlocReps();
        }

    }, [formValues.locado]);

    useEffect(() => {

        let quartosAloc = {}

        if (!loading && locacaoValues.alojamento !== '') {

            let repSelected = alocReps.filter((obj) => obj.value === locacaoValues.alojamento)[0]

            if (repSelected && repSelected.quartos.length > 0) {

                quartosAloc = repSelected.quartos.map((obj) => {

                    return {

                        label: obj.quarto_name,
                        value: obj.identifier

                    }

                })

            }

        }

        setAlocQuartos(quartosAloc)

    }, [locacaoValues.alojamento])

    return (
        <div className={styles.colabBody} >
            <div className={styles.colabInfo} >

                <div className={styles.colabPicContainer}>
                    <div className={styles.colabPic} ></div>
                </div>

                <div className={styles.colabContainerInfo} >
                    <ColabInfoChild
                        title="MATRICULA"
                        text={colabInfo.MATRICULA}
                    />
                    <ColabInfoChild
                        title="NOME"
                        text={colabInfo.NOME}
                    />
                    <ColabInfoChild
                        title="CPF"
                        text={ colabInfo.CPF ? formatCPF( colabInfo.CPF ) : '' }
                    />
                    <ColabInfoChild
                        title="CENTRO DE CUSTO"
                        text={colabInfo.CENTRO_CUSTO}
                    />
                    <ColabInfoChild
                        title="FUNÇÃO"
                        text={colabInfo.FUNCAO}
                    />
                    <ColabInfoChild
                        title="DATA ADMISSÃO"
                        text={ colabInfo.ADMISSAO ? formatDate( colabInfo.ADMISSAO ) : '' }
                    />
                    <ColabInfoChild
                        title="DATA DEMISSÃO"
                        text={ colabInfo.DEMISSAO ? formatDate( colabInfo.DEMISSAO ) : '' }
                    />
                    <ColabInfoChild
                        title="MÃO DE OBRA"
                        text={colabInfo.MAO_OBRA}
                    />
                    <ColabInfoChild
                        title="SEXO"
                        text={colabInfo.SEXO}
                    />
                    <ColabInfoChild
                        title="DATA DE NASCIMENTO"
                        text={colabInfo.DATA_NASC ? formatDate( colabInfo.DATA_NASC ) : '' }
                    />
                    <ColabInfoChild
                        title="SITUAÇÃO"
                        text={colabInfo.STATUS}
                    />
                    <ColabInfoChild
                        title="RG"
                        text={colabInfo.RG}
                    />
                    <ColabInfoChild
                        title="UF DO RG"
                        text={colabInfo.RG_UF}
                    />
                    <ColabInfoChild
                        title="MUNICIPIO DE RESIDÊNCIA"
                        text={colabInfo.MUNICIPIO + ' - ' + colabInfo.ESTADO}
                    />
                    <ColabInfoChild
                        title="PIS"
                        text={colabInfo.PIS}
                    />

                    <ColabInfoChild
                        title="CONTATO"
                        text={`( ${colabInfo.DDDFONE} ) ${colabInfo.TELEFONE}`}
                    />

                </div>

            </div>
            <div className={styles.colabFill} >

                <div className={styles.fillContainer} >

                    <h3>PREENCHER INFORMAÇÕES</h3>

                    <form>

                        <ColabField
                            title="CRACHÁ DE ACESSO"
                            type="text"
                            value={formValues.cracha}
                            onChange={(e) => handleChange('cracha', e.target.value)}
                        />
                        <ColabField
                            title="CONTRATO"
                            type="select"
                            value={formValues.contrato}
                            options={contratos}
                            onChange={(e) => handleChange('contrato', e.target.value)}
                        />
                        <ColabField
                            title="DISCIPLINA"
                            type="select"
                            value={formValues.disciplina}
                            options={disciplinas}
                            onChange={(e) => handleChange('disciplina', e.target.value)}
                        />
                        <ColabField
                            title="ENCARREGADO"
                            type="select"
                            value={formValues.encarregado}
                            options={lideres}
                            onChange={(e) => handleChange('encarregado', e.target.value)}
                        />
                        <ColabField
                            title="SUPERVISOR"
                            type="select"
                            value={formValues.supervisor}
                            options={lideres}
                            onChange={(e) => handleChange('supervisor', e.target.value)}
                        />
                        <ColabField
                            title="SUPERVISOR GERAL"
                            type="select"
                            value={formValues.supGeral}
                            options={lideres}
                            onChange={(e) => handleChange('supGeral', e.target.value)}
                        />
                        <ColabField
                            title="HOSPEDAGEM"
                            type="select"
                            options={["ALOJADO", "LOCAL"]}
                            value={formValues.hosped}
                            onChange={(e) => handleChange('hosped', e.target.value)}
                        />
                        <ColabField
                            title="LOCADO"
                            type="select"
                            value={formValues.locado}
                            options={obras}
                            onChange={(e) => handleChange('locado', e.target.value)}
                        />

                        <button onClick={handleSubmit1} disabled={btnSave1Disable} >{btnSave1}</button>

                    </form>

                </div>

                {

                    formValues.hosped === 'ALOJADO' && (
                        <div className={styles.fillContainer} >
                            <h3>ALOJAMENTO</h3>

                            <form>

                                <ColabField
                                    title="ALOJAMENTO"
                                    type="select"
                                    value={alojValues.alojamento}
                                    options={republicas}
                                    onChange={(e) => handleChangeAloj('alojamento', e.target.value)}
                                />

                                <ColabField
                                    title="QUARTO"
                                    type="select"
                                    value={alojValues.quarto}
                                    options={quartos}
                                    onChange={(e) => handleChangeAloj('quarto', e.target.value)}
                                />

                                <ColabField
                                    title="CHECK-IN"
                                    type="date"
                                    value={alojValues.check_in}
                                    onChange={(e) => handleChangeAloj('check_in', e.target.value)}
                                />

                                <ColabField
                                    title="CHECK-OUT"
                                    type="date"
                                    value={alojValues.check_out}
                                    onChange={(e) => handleChangeAloj('check_out', e.target.value)}
                                />

                                <button disabled={btnSave2Disable} onClick={handleSubmit2} >{btnSave2}</button>

                            </form>

                        </div>
                    )

                }

                {formValues.locado !== '' && (

                    <div className={styles.fillContainer} >
                        <h3>LOCAÇÃO</h3>

                        <form>

                            <ColabField
                                title="ALOJAMENTO"
                                type="select"
                                options={alocReps}
                                value={locacaoValues.alojamento}
                                onChange={(e) => handleChangeLocacao('alojamento', e.target.value)}
                            />

                            <ColabField
                                title="QUARTO"
                                type="select"
                                options={alocQuartos}
                                value={locacaoValues.quarto}
                                onChange={(e) => handleChangeLocacao('quarto', e.target.value)}
                            />

                            <button disabled={btnSave3Disable} onClick={handleSubmit3} >{btnSave3}</button>

                        </form>
                    </div>

                )}

                <div className={styles.folgasField} >
                    <h3>FOLGAS DE CAMPO</h3>

                    <div className={styles.folgasTableContainer} >
                        <table>
                            <thead>
                                <tr>
                                    <th>VALOR</th>
                                    <th>DATA</th>
                                    <th>DIAS GOZO</th>
                                    <th>PRÓXIMA FOLGA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    folgasCampo.map(( obj ) => (
                                        <tr>
                                            <td>R$ { formatValorMoney( obj.valor_passagem ) }</td>
                                            <td>{ formatarData( obj.data_folga ) }</td>
                                            <td>{ obj.dias_gozo }</td>
                                            <td>{ formatarData( obj.data_prox_folga ) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ColabBody;