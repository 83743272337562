import style from './OverlayView.module.css';

import { LuLoader2 } from "react-icons/lu";
import { useEffect, useState } from 'react';
import axios from 'axios';


const OverlayView = ({ id, closeRep }) => {

    const [isLoading, setIsLoading] = useState(true);

    const [republica, setRepublica] = useState([]);
    const [quartos, setQuartos ] = useState([]);

    useEffect(() => {

        const searchRep = async () => {

            try {

                const { data } = await axios.post('http://localhost/Painel/API/GetRepDetails/', {
                    identifier: id
                })

                console.log(data)

                setRepublica(data)
                setQuartos(data[0].quartos)
                setIsLoading(false)

            } catch (error) {

                console.log(error)

            }

        }

        searchRep();

    }, [])

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div className={style.pdfBox} ></div>
                <div
                    className={style.closeBox}
                    onClick={ () => closeRep() }
                ></div>
            </div>
            <div className={style.body} >
                {
                    isLoading ? (

                        <div className={style.loadingContent} >
                            <div className={style.loadingBox} >
                                <LuLoader2
                                    size={32}
                                    className={style.loadIcon}
                                />
                            </div>
                        </div>

                    ) : (

                        <div className={ style.quartosContent } >
                            <div className={ style.titleQuartos } >
                                <p>QUARTOS</p>
                            </div>

                            {
                                quartos.map( obj => (

                                    <div className={ style.quartoContainer } key={ obj.identifier } >
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={ 4 } >{ obj.quarto_name }</th>
                                                </tr>
                                                <tr>
                                                    <th>MATRICULA</th>
                                                    <th>NOME</th>
                                                    <th>FUNÇÃO</th>
                                                    <th>ENCARREGADO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    obj.colaboradores.map( colab => (

                                                        <tr key={ colab.matricula } >
                                                            <td>{ colab.matricula }</td>
                                                            <td>{ colab.nome }</td>
                                                            <td>{ colab.funcao }</td>
                                                            <td> - </td>
                                                        </tr>

                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                ))
                            }

                        </div>

                    )

                }

            </div>
        </div>
    )
}

export default OverlayView;