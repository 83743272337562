import axios from "axios";
import NavBar from "../Componentes/NavBar";
import RepsBody from "../Componentes/RepsBody";
import { useEffect, useState } from "react";
import Overlay from "../Componentes/Overlay";
import RepsContainer from "../Componentes/RepsContainer";
import OverlayView from "../Componentes/Republicas/OverlayView";

const NewReps = () => {

    const [republicas, setRepubicas] = useState([])
    const [republicasFilter, setRepubicasFilter] = useState([])

    const [selectedID, setSelectedID] = useState('')

    const [formValues, setFormValues] = useState({
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        CEP: '',
        cidade: '',
        estado: '',
        centro_custo: '',
        nome_rep: ''
    });

    const clearFormValues = () => {

        setFormValues({
            ...formValues,
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            CEP: '',
            cidade: '',
            estado: '',
            centro_custo: '',
            nome_rep: ''
        })

    }

    const closeRepInfo = () => {

        clearFormValues();
        setOverlayState( false );

    }


    const [ overlayView, setOverlayView ] = useState( false )
    const [ idView, setIdView ] = useState( null )


    const [ overlayEdit, setOverlayEdit ] = useState( false )
    const [ overlayDelete, setOverlayDelete ] = useState( false )

    const openRepView = ( id ) => {

        setOverlayView( true );
        setIdView( id );

    }

    const closeRepView = () => {

        setOverlayView( false );
        setIdView( null );

    }

    const [overlayState, setOverlayState] = useState(false)

    const getReps = async () => {

        try {

            const { data } = await axios.get("http://localhost/Painel/API/GetNewReps/")
            setRepubicas(data);
            setRepubicasFilter(data);

            console.log( data )

        } catch (error) {

            alert(error.message);

        }

    }

    useEffect(() => {

        getReps();

    }, [])

    const openRep = (value) => {

        setSelectedID(value);
        setOverlayState(true);

    }

    return (
        <>
            <NavBar
                title="REPÚBLICAS"
            />

            <RepsBody
                dados={republicasFilter}
                openRep={openRep}
                openView={ openRepView }
            />

            {

                overlayView && (

                    <Overlay state={ true } >
                        <OverlayView
                            id={ idView }
                            closeRep={ closeRepView }
                        />
                    </Overlay>

                )

            }

            {
                overlayState && (

                    <Overlay
                        state={overlayState}
                    >

                        <RepsContainer
                            repID={selectedID}
                            repInfo={formValues}
                            setRepInfo={setFormValues}
                            closeContainer={closeRepInfo}
                        />

                    </Overlay>

                )
            }

        </>
    )

}

export default NewReps;